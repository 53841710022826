'use es6';

import ajax from './ajax';
import { getRelatedArticlesUrl } from './urls';
import { sanitizeHTML } from './utils';
function renderHeading(heading) {
  return `<h3>${sanitizeHTML(heading)}</h3>`;
}
function renderRelatedArticlesContainer(children) {
  return `<h6><ul>${children}</ul></h6>`;
}
function renderRelatedArticles(articles = []) {
  return articles.map(article => `<li><a href="${encodeURI(article.url)}" data-article-id="${article.articleId || ''}">${sanitizeHTML(article.title)}</a></li>`).join('');
}
export function start(currentUrl = document.location.href) {
  const container = document.getElementById('kb-related-articles-container');
  const innerContainer = document.getElementById('kb-related-articles');
  if (container && innerContainer) {
    const portalId = container.getAttribute('portal-id');
    const knowledgeBaseId = container.getAttribute('knowledge-base-id');
    const currentArticleId = container.getAttribute('article-id');
    const currentArticleLanguage = container.getAttribute('article-language');
    const contactVid = container.getAttribute('contact-vid');
    const heading = container.getAttribute('heading');
    const ssoLogin = container.getAttribute('sso-login');
    const publicHubapiDomain = container.getAttribute('public-hubapi-domain');
    const knowledgeBaseType = container.getAttribute('knowledge-base-type');
    const apiEndpointUrl = getRelatedArticlesUrl(publicHubapiDomain, portalId, knowledgeBaseId, currentArticleId, currentArticleLanguage, currentUrl, contactVid, ssoLogin, knowledgeBaseType);
    ajax.get(apiEndpointUrl, {
      callback: ({
        recommendedArticles = []
      }) => {
        if (recommendedArticles.length > 0) {
          innerContainer.innerHTML = `${renderHeading(heading)}${renderRelatedArticlesContainer(renderRelatedArticles(recommendedArticles))}`;
        }
      }
    });
  }
}