'use es6';

export function getRelatedArticlesUrl(publicHubapiDomain, portalId, knowledgeBaseId, currentArticleId, currentArticleLanguage, currentUrl, vid, ssoLogin, knowledgeBaseType) {
  const path = `knowledge-content-recommender/v1/knowledge-base/${knowledgeBaseId}/articles`;
  const queryParams = {
    portalId,
    currentArticleId,
    currentArticleLanguage,
    currentUrl,
    vid,
    ssoLogin,
    knowledgeBaseType
  };
  const query = Object.keys(queryParams).filter(key => Boolean(queryParams[key])).map(key => `${key}=${encodeURI(queryParams[key])}`).join('&');
  return `https://${publicHubapiDomain}/${path}?${query}`;
}