'use es6';

function noop() {}
class Ajax {
  send(url, options, method) {
    const httpRequest = new XMLHttpRequest();
    const opts = Object.assign({
      callback: noop,
      errorCallback: noop
    }, options);
    httpRequest.open(method, url);
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState === 4) {
        const isSuccess = httpRequest.status >= 200 && httpRequest.status < 300 || httpRequest.status === 304;
        try {
          const data = JSON.parse(httpRequest.responseText);
          if (isSuccess) {
            opts.callback(data);
          } else {
            opts.errorCallback(data);
          }
        } catch (error) {
          opts.errorCallback(httpRequest);
        }
      }
    };
    httpRequest.send();
  }
  get(url, options) {
    this.send(url, options, 'GET');
  }
}
export default new Ajax();